*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  /* margin: 0; */
  overflow-x: hidden;
  font-family: IBM, Plex, "Serif";
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dropdown_list {
  position: absolute;
  left: 25%;
  bottom: 0%;
  width: 100px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.dropdown_list p {
  padding: 10px;
  margin: 0;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown_list p:hover {
  background-color: #f0f0f0; /* Add a hover effect */
}


